<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
      style="height: 200px"
    >
      <analytics-congratulation-john></analytics-congratulation-john>
    </v-col>
  </v-row>
</template>

<script>
import { reactive, ref } from '@vue/composition-api'

// demos
import AnalyticsCongratulationJohn from './AnalyticsCongratulationJohn.vue'

export default {
  name: 'DashboardsStatistics',
  components: {
    AnalyticsCongratulationJohn,
  },
  setup() {
    const profitLineChart = {
      statTitle: 'Total Profit',
      statistics: '$86.4k',
      series: [
        {
          data: [0, 20, 55, 30, 15, 25, 46],
        },
      ],
    }

    const totalProfitOptions = {
      statTitle: 'Total Profit',
      icon: 'mdi-poll',
      color: 'secondary',
      subtitle: 'Weekly Project',
      statistics: '$25.6k',
      change: '+42%',
    }

    // vertical card options
    const newProjectOptions = {
      statTitle: 'New Project',
      icon: 'mdi-label-variant-outline',
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    const sessionBarChart = {
      statTitle: 'Sessions',
      statistics: '2,856',
      series: [
        {
          name: '2020',
          data: [45, 85, 65, 50, 70],
        },
      ],
    }

    const profitLineChartRef = ref(null)
    const fileDetails = reactive({
      fileInputKey: '',
      fileInputItem: {},
    })

    return {
      profitLineChartRef,
      profitLineChart,
      totalProfitOptions,
      newProjectOptions,
      sessionBarChart,
      fileDetails,
    }
  },
}
</script>
